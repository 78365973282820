body {
    background-color: #000;
    color: #0f0;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .App-header {
    background-color: #000;
    padding: 20px;
    color: #0f0;
  }
  
  h1 {
    font-size: 3em;
    text-shadow: 2px 2px #f0f;
  }
  
  .game-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .game-card {
    background-color: #000;
    border: 2px solid #0f0;
    color: #0f0;
    margin: 10px;
    padding: 15px;
    width: 200px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .game-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px #0f0;
  }
  
  .game-card h2 {
    color: #f0f;
  }
  
  .back-button {
    background-color: #f0f;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .App-footer {
    background-color: #000;
    color: #0f0;
    padding: 10px;
    margin-top: auto;
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .blink {
    animation: blink 1s linear infinite;
    color: #ff0;
  }
  
  /* Snake Game Styles */
  .snake-game {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .game-board {
    width: 400px;
    height: 400px;
    border: 2px solid #0f0;
    position: relative;
  }
  
  .snake-segment {
    width: 20px;
    height: 20px;
    background-color: #0f0;
    position: absolute;
  }
  
  .food {
    width: 20px;
    height: 20px;
    background-color: #f00;
    position: absolute;
  }
  
  /* Whack-a-Mole Game Styles */
  .whack-a-mole {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .whack-a-mole .game-grid {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-gap: 10px;
    margin-top: 20px;
  }
  
  .mole-hole {
    width: 100px;
    height: 100px;
    background-color: #654321;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .mole {
    width: 80px;
    height: 80px;
    background-color: #a52a2a;
    border-radius: 50%;
  }
  
  .start-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #0f0;
    color: #000;
    border: none;
    cursor: pointer;
  }
  
  .game-info {
    margin-top: 20px;
    font-size: 24px;
    color: #0f0;
  }
  
  .restart-button {
    background-color: #0f0;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }